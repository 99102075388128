import React, { useState, useEffect } from "react";
import "./CompleteProfileStep1.css";
import heading from "../../assets/images/CompleteProfile/Path 489.png";
import mehnatilog from "../../assets/images/CompleteProfile/MEHNATI ILLUSTRATIONS-15.png";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import axios from "axios";
import { Register } from "../../Redux/Auth/AuthActions";
import { FiCheck } from "react-icons/fi";
import { IoReturnUpBackSharp } from "react-icons/io5";
import { RiDeleteBin2Line } from "react-icons/ri";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AiFillEye } from "react-icons/ai";
import { AiFillEyeInvisible } from "react-icons/ai";
import moment from "moment";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";

function CompleteProfileStep1() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, error, loading } = useSelector((state) => state.Auth);
  const [pages, setpages] = useState(0);
  const [districtslist, setdistrictslist] = useState("");
  const [Gender, setGender] = useState("");
  const [FullName, setFullName] = useState("");
  const [Phone, setPhone] = useState("");
  const [dateofbirth, setdateofbirth] = useState("");
  const [district, setdistrict] = useState("");
  const [school, setschool] = useState("");
  const [education, seteducation] = useState("");
  const [level, setlevel] = useState("");
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [confirm, setconfirm] = useState("");
  const [coverletter, setcoverletter] = useState("");
  const [portfolio, setportfolio] = useState("");
  const [expertiselist, setexpertiselist] = useState("");
  const [expertise, setexpertise] = useState("");
  const [certificate, setcertificate] = useState("");
  const [experiencelevellist, setexperiencelevellist] = useState("");
  const [degreelist, setdegreelist] = useState("");
  const [addToCardAction, setAddToCardAction] = useState(false);

  const [selectedImage, setSelectedImage] = useState(null);
  //disable button 1
  const [cvSelected, setcvSelected] = useState(null);
  const [disabled1, setdisabled1] = useState(true);
  const [disabled2, setdisabled2] = useState(true);
  const [disabled3, setdisabled3] = useState(true);
  const [disabled4, setdisabled4] = useState(true);
  const [disabled5, setdisabled5] = useState(true);
  const [doned, setdone] = useState(false);
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  // const passwordregex =
  //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!#%^&*])[A-Za-z\d@$!#%^&*]{8,}$/;
  const [agreeValue, setAgreeValue] = useState(false); // Initialize the state with an empty string

  useEffect(() => {
    if (
      FullName === "" ||
      Gender === "" ||
      Phone === "" ||
      dateofbirth === "" ||
      district === ""
    ) {
      setdisabled1(true);
    } else {
      setdisabled1(false);
    }
  }, [FullName, Gender, Phone, dateofbirth, district]);

  //disable button 2

  useEffect(() => {
    if (
      school === "" ||
      education === "" ||
      expertise === "" ||
      level === "" ||
      experiencelevellist === ""
    ) {
      setdisabled2(true);
    } else {
      setdisabled2(false);
    }
  }, [school, education, expertise, level, experiencelevellist]);

  //disable button 3

  useEffect(() => {
    if (!selectedImage || !cvSelected) {
      setdisabled3(true);
    } else {
      setdisabled3(false);
    }
  }, [selectedImage, cvSelected, agreeValue]);

  //disable button 4

  useEffect(() => {
    if (
      email === "" ||
      !emailRegex.test(email) ||
      // !passwordregex.test(password) ||
      password === "" ||
      confirm === "" ||
      password !== confirm ||
      !agreeValue
    ) {
      setdisabled4(true);
    } else {
      setdisabled4(false);
    }
  }, [email, password, confirm, agreeValue]);

  const handlenext = () => {
    setpages(pages + 1);
  };

  const getdistricts = async () => {
    const res = await axios.get(process.env.REACT_APP_API + "/disrict");
    setdistrictslist(res.data.data);
  };
  const getexpertiselist = async () => {
    const res = await axios.get(process.env.REACT_APP_API + "/expertise");
    setexpertiselist(res.data.data);
  };
  const getdexperiencelevellist = async () => {
    const res = await axios.get(process.env.REACT_APP_API + "/experience");
    setexperiencelevellist(res.data.data);
  };
  const getdegreelist = async () => {
    const res = await axios.get(process.env.REACT_APP_API + "/education");
    setdegreelist(res.data.data);
  };

  useEffect(() => {
    getdistricts();
    getexpertiselist();
    getdexperiencelevellist();
    getdegreelist();
  }, []);
  const handleDistrictChange = (event) => {
    const selectedId = event.target.value;
    setdistrict(selectedId);
  };
  const handlexpertisechange = (event) => {
    const selectedId = event.target.value;
    setexpertise(selectedId);
  };
  const handleschool = (event) => {
    const selectedId = event.target.value;
    seteducation(selectedId);
  };
  const handlelevel = (event) => {
    const selectedId = event.target.value;
    setlevel(selectedId);
  };
  const degree = [
    { id: 1, name: "BP Degree" },
    { id: 2, name: "BT Degree" },
    { id: 3, name: "TS Degree" },
    { id: 4, name: "LT Degree" },
    { id: 5, name: "Bachelor's Degree" },
    { id: 6, name: "Master's Degree" },
  ];
  const handlePaste = (event) => {
    event.preventDefault();

    // Access the clipboard data
    const clipboardData = event.clipboardData || window.clipboardData;

    // Get the pasted text from the clipboard
    const pastedText = clipboardData.getData("text/plain");

    // Do something with the pasted text (e.g., display an alert)
  };
  const handleFileSelected = (event) => {
    const fileInput = event.target;
    // const fileNameInput = document.getElementById("file-name");

    // if (fileInput.files.length > 0) {
    //   const fileName = fileInput.files[0].name;
    //   fileNameInput.value = fileName;
    // } else {
    //   fileNameInput.value = "";
    // }

    setcertificate(fileInput.files[0]);
  };

  const [showpassword, setshowpassword] = useState(false);
  const [showpassword1, setshowpassword1] = useState(false);
  const handleFileSelected2 = (event) => {
    const fileInput = event.target;
    setcvSelected(fileInput.files[0]);
  };
  const handleFileSelected3 = (event) => {
    const fileInput = event.target;
    setcoverletter(fileInput.files[0]);
  };
  const handleFileSelected4 = (event) => {
    const fileInput = event.target;
    setportfolio(fileInput.files[0]);
  };
  // const handleFileSelected2 = (event) => {
  //   const fileInput = event.target;
  //   const fileNameInput = document.getElementById("file-name2");

  //   if (fileInput.files.length > 0) {
  //     const fileName = fileInput.files[0].name;
  //     fileNameInput.value = fileName;
  //   } else {
  //     fileNameInput.value = "";
  //   }
  // };
  const [image, setimage] = useState(null);

  const handleImageSelected = (event) => {
    const imageInput = event.target;
    setSelectedImage(null);

    if (imageInput.files && imageInput.files[0]) {
      const reader = new FileReader();

      reader.onload = function (e) {
        setSelectedImage(e.target.result);
        setimage(imageInput.files[0]);
      };

      reader.readAsDataURL(imageInput.files[0]);
    }
  };
  const data = new FormData();
  data.append("full_name", FullName);
  data.append("gender", Gender);
  data.append("year_of_birthday", dateofbirth);
  data.append("district_id", district);
  data.append("school", school);
  data.append("email", email);
  data.append("password", password);
  data.append("phone_number", Phone);
  data.append("expertise", expertise);
  data.append("experience_level", level);
  data.append("education_level", education);
  data.append("certificate", certificate);
  data.append("img_url", image);
  data.append("cv", cvSelected);
  data.append("cover_letter", coverletter);
  data.append("portfolio", portfolio);
  const handleRegister = () => {
    dispatch(Register(data));
    setAddToCardAction(true);
  };

  useEffect(() => {
    let actionDispatched = false;
    if (addToCardAction && !loading) {
      if (!actionDispatched && user && !error) {
        NotificationManager.success("Check Your Email , to Verify Your Email!");
        actionDispatched = true;
      } else if (error) {
        NotificationManager.error(error);
      }
      setAddToCardAction(false);
    }
  }, [loading, user, error, addToCardAction]);

  return (
    <div className="Main_complete_prof">
      <div className="Aboutus_heading">
        <div className="Aboutus_heading_img">
          <img src={heading} alt="heading" className="heading" />
        </div>

        <div className="Aboutus_heading_text">
          <div className="Aboutus_heading_text_title"> Technicians Sign up</div>

          <div className="Aboutus_heading_text_desc">
            {" "}
            <a href="/">Home /</a>{" "}
            <span className="head_span">Technicians Sign up</span>
          </div>
        </div>
      </div>{" "}
      <div className="CompleteProfileStep1_container">
        <div className="CompleteProfileStep1_container_left">
          <img src={mehnatilog} alt="mehnatilog" className="mehnatilog" />
        </div>
        <div className="CompleteProfileStep1_container_right">
          <div className="CompleteProfileStep1_container_right_heading">
            <div className="CompleteProfileStep1_container_right_heading_title">
              {" "}
              <div className="tech">
                <div className="backback">
                  {" "}
                  {(pages == 1 || pages == 2 || pages == 3) && (
                    <div onClick={() => setpages(pages - 1)}>
                      <IoReturnUpBackSharp />
                    </div>
                  )}
                </div>{" "}
                Hello Technicians!
              </div>
              <div>
                {pages == 0 && (
                  <div className="Form_container">
                    <div className="complete_data_cont">
                      <div className="null_container">
                        {FullName !== "" && (
                          <FiCheck size={30} color={"#A2BF83"} />
                        )}
                      </div>

                      <input
                        type="text"
                        placeholder="Full Name*"
                        value={FullName}
                        className={
                          FullName !== ""
                            ? "input_complete_1_check"
                            : "input_complete_1"
                        }
                        required
                        onChange={(e) => setFullName(e.target.value)}
                      />
                    </div>
                    <div className="complete_data_cont">
                      <div className="null_container">
                        {Gender !== "" && (
                          <FiCheck size={30} color={"#A2BF83"} />
                        )}
                      </div>
                      <select
                        // className="input_complete_2"

                        className={
                          Gender !== ""
                            ? "input_complete_2_checked"
                            : "input_complete_2"
                        }
                        required
                        onChange={(e) => setGender(e.target.value)}
                        defaultValue={Gender}
                      >
                        <option value="" selected disabled>
                          {" "}
                          Gender*{" "}
                        </option>
                        <option value="Female"> Female </option>
                        <option value="Male"> Male </option>
                      </select>
                    </div>
                    <div className="complete_data_cont">
                      <div className="null_container">
                        {Phone !== "" && (
                          <FiCheck size={30} color={"#A2BF83"} />
                        )}
                      </div>
                      <PhoneInput
                        country={"lb"}
                        value={Phone}
                        onChange={(e) => setPhone(e)}
                        // className="input_complete_1"
                        className={
                          Phone !== ""
                            ? "input_complete_1_check"
                            : "input_complete_1"
                        }
                      />
                    </div>
                    <div className="dateofbirth">
                      click on the calendar to select your date of birth
                    </div>
                    <div className="complete_data_cont">
                      <div className="null_container">
                        {dateofbirth !== "" && (
                          <FiCheck size={30} color={"#A2BF83"} />
                        )}
                      </div>

                      <input
                        type="date"
                        placeholder="Select a date"
                        value={dateofbirth}
                        // className="input_complete_1"
                        className={
                          dateofbirth !== ""
                            ? "input_complete_1_check"
                            : "input_complete_12"
                        }
                        min={moment()
                          .subtract(100, "years")
                          .format("YYYY-MM-DD")}
                        max={moment()
                          .subtract(16, "years")
                          .format("YYYY-MM-DD")}
                        required
                        onChange={(e) => setdateofbirth(e.target.value)}
                      />
                    </div>

                    <div className="complete_data_cont">
                      <div className="null_container">
                        {district !== "" && (
                          <FiCheck size={30} color={"#A2BF83"} />
                        )}
                      </div>
                      <select
                        // className="input_complete_2"
                        className={
                          district !== ""
                            ? "input_complete_2_checked"
                            : "input_complete_2"
                        }
                        required
                        onChange={handleDistrictChange}
                        defaultValue={district}
                      >
                        <option value="" selected disabled>
                          {" "}
                          Address By District{" "}
                        </option>

                        {districtslist &&
                          districtslist.map((item) => (
                            <option value={item.id}>{item.name_en}</option>
                          ))}
                      </select>
                    </div>

                    {/* <p className="allabove">All above fields are required*</p> */}
                    <div
                      className={`button_container ${
                        disabled1 ? "disabled" : ""
                      }`}
                      onClick={handlenext}
                    >
                      <button
                        className="nextbutton"
                        type="submit"
                        disabled={disabled1}
                      >
                        {" "}
                        Next{" "}
                      </button>
                    </div>
                  </div>
                )}
                {pages == 1 && (
                  <div className="Form_container">
                    <div className="complete_data_cont">
                      <div className="null_container">
                        {school !== "" && (
                          <FiCheck size={30} color={"#A2BF83"} />
                        )}
                      </div>
                      <input
                        type="text"
                        placeholder="School*"
                        // className="input_complete_1"
                        value={school}
                        className={
                          school !== ""
                            ? "input_complete_1_check"
                            : "input_complete_1"
                        }
                        required
                        onChange={(e) => setschool(e.target.value)}
                      />
                    </div>
                    <div className="complete_data_cont_1">
                      <div className="profile_data_cont_2">
                        <div className="null_container">
                          {certificate && (
                            <FiCheck size={30} color={"#A2BF83"} />
                          )}
                        </div>
                        {/* <input
                          type="file"
                          accept="application/pdf"
                          placeholder="Certificate acquired"
                          className="certificate"
                          required
                        /> */}
                        <div className="inputs_upload">
                          <input
                            id="file-upload"
                            type="file"
                            placeholder="Certificate acquired"
                            accept="application/pdf"
                            className="hidden"
                            onChange={(event) => handleFileSelected(event)}
                          />
                          <input
                            id="file-name"
                            type="text"
                            placeholder="Certificate acquired"
                            disabled
                            value={certificate ? certificate.name : ""}
                            className={
                              certificate
                                ? "certificate checked_border"
                                : "certificate"
                            }
                          />

                          <label for="file-upload" className="div_add">
                            +
                          </label>
                          <div onClick={() => setcertificate("")}>
                            <RiDeleteBin2Line className="delete" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="complete_data_cont">
                      <div className="null_container">
                        {education !== "" && (
                          <FiCheck size={30} color={"#A2BF83"} />
                        )}
                      </div>
                      <select
                        // className="input_complete_2"
                        className={
                          education !== ""
                            ? "input_complete_2_checked"
                            : "input_complete_2"
                        }
                        required
                        onChange={handleschool}
                        defaultValue={education}
                      >
                        <option value="" selected disabled>
                          {" "}
                          Education Level*{" "}
                        </option>
                        {degreelist &&
                          degreelist.map((item) => (
                            <option value={item.id}>{item.name_en}</option>
                          ))}
                      </select>
                    </div>
                    <div className="complete_data_cont">
                      <div className="null_container">
                        {expertise !== "" && (
                          <FiCheck size={30} color={"#A2BF83"} />
                        )}
                      </div>

                      <select
                        // className="input_complete_2"
                        className={
                          expertise !== ""
                            ? "input_complete_2_checked"
                            : "input_complete_2"
                        }
                        required
                        onChange={handlexpertisechange}
                        defaultValue={expertise}
                      >
                        <option value="" selected disabled>
                          {" "}
                          Field of Experience*{" "}
                        </option>
                        {expertiselist &&
                          expertiselist.map((item) => (
                            <option value={item.id}>{item.name_en}</option>
                          ))}
                      </select>
                    </div>
                    <div className="complete_data_cont">
                      <div className="null_container">
                        {level !== "" && (
                          <FiCheck size={30} color={"#A2BF83"} />
                        )}
                      </div>
                      <select
                        // className="input_complete_2"
                        className={
                          level !== ""
                            ? "input_complete_2_checked"
                            : "input_complete_2"
                        }
                        required
                        onChange={handlelevel}
                        defaultValue={level}
                      >
                        <option value="" selected disabled>
                          {" "}
                          Experience Level*{" "}
                        </option>
                        {experiencelevellist &&
                          experiencelevellist.map((item) => (
                            <option value={item.id}>{item.name_en}</option>
                          ))}
                      </select>
                    </div>

                    {/* <p className="allabove">All above fields are required*</p> */}
                    <div
                      className={`button_container ${
                        disabled2 ? "disabled" : ""
                      }`}
                      onClick={handlenext}
                    >
                      <button
                        className="nextbutton"
                        type="submit"
                        disabled={disabled2}
                      >
                        {" "}
                        Next{" "}
                      </button>
                    </div>
                  </div>
                )}
                {pages == 2 && (
                  <div className="Form_container">
                    <div className="profile_img_cont_2 ">
                      <div className="null_container_2">
                        {selectedImage ? (
                          <div>
                            <img
                              src={selectedImage}
                              alt="Selected Image Preview"
                              className="profile_img_prev"
                            />
                          </div>
                        ) : (
                          <>
                            {" "}
                            <div
                              id="image-preview"
                              className="text_cont_profile_pic"
                            ></div>
                            <div className="text_cont_profile_pic">
                              Profile Picture * (Required)
                            </div>
                            <div className="subtext_cont_profile_pic">
                              {"(500x500px )"}
                            </div>
                          </>
                        )}
                      </div>
                      <input
                        type="file"
                        className="profile_img"
                        accept="image/*"
                        onChange={handleImageSelected}
                      />
                    </div>
                    <div className="cv_attachment">
                      <div className="inputs_upload2">
                        <label for="file-upload2" className="div_add2">
                          +
                        </label>
                        <input
                          id="file-name2"
                          type="text"
                          disabled
                          value={cvSelected ? cvSelected.name : ""}
                          className={
                            cvSelected
                              ? "certificate checked_border"
                              : "certificate"
                          }
                          placeholder="Upload CV *(Required in PDF) "
                        />
                      </div>

                      <input
                        id="file-upload2"
                        type="file"
                        accept="application/pdf"
                        className="hidden"
                        onChange={(event) => handleFileSelected2(event)}
                      />
                      <div onClick={() => setcvSelected("")}>
                        <RiDeleteBin2Line className="delete" />
                      </div>
                    </div>

                    <div className="cv_attachment">
                      <div className="inputs_upload2">
                        <label for="file-upload" className="div_add2">
                          +
                        </label>

                        <input
                          id="file-name2"
                          type="text"
                          disabled
                          value={coverletter ? coverletter.name : ""}
                          className={
                            coverletter
                              ? "certificate checked_border"
                              : "certificate"
                          }
                          placeholder="Upload Cover Letter "
                        />
                      </div>
                      <input
                        id="file-upload"
                        type="file"
                        accept="application/pdf"
                        className="hidden"
                        onChange={(event) => handleFileSelected3(event)}
                      />
                      <div onClick={() => setcoverletter("")}>
                        <RiDeleteBin2Line className="delete" />
                      </div>
                    </div>
                    <div className="cv_attachment">
                      <div className="inputs_upload2">
                        <label for="file-upload3" className="div_add2">
                          +
                        </label>

                        <input
                          id="file-name2"
                          type="text"
                          disabled
                          value={portfolio ? portfolio.name : ""}
                          // className="certificate"
                          className={
                            portfolio
                              ? "certificate checked_border"
                              : "certificate"
                          }
                          placeholder="Attach Portfolio "
                        />
                      </div>
                      <input
                        id="file-upload3"
                        type="file"
                        accept="application/pdf"
                        className="hidden"
                        onChange={(event) => handleFileSelected4(event)}
                      />
                      <div onClick={() => setportfolio("")}>
                        <RiDeleteBin2Line className="delete" />
                      </div>
                    </div>
                    <div
                      className={`button_container ${
                        disabled3 ? "disabled" : ""
                      }`}
                      onClick={handlenext}
                      disabled={disabled3}
                    >
                      <button
                        className="nextbutton"
                        type="submit"
                        disabled={disabled3}
                      >
                        {" "}
                        Next{" "}
                      </button>
                    </div>
                  </div>
                )}
                {pages == 3 && (
                  <div className="Form_container">
                    <NotificationContainer />
                    <div className="signup_data">
                      <div className="null_container">
                        {email !== "" && emailRegex.test(email) && (
                          <FiCheck size={30} color={"#A2BF83"} />
                        )}
                      </div>
                      <input
                        type="email"
                        placeholder="Email Address (use a professional e-mail)"
                        // className="input_complete_1"
                        value={email}
                        className={
                          email !== ""
                            ? "input_complete_1_check"
                            : "input_complete_1"
                        }
                        required
                        onChange={(e) => setemail(e.target.value)}
                      />
                    </div>

                    <div className="signup_data">
                      <div className="null_container">
                        {password !== "" &&
                          password === confirm &&
                          emailRegex.test(email) && (
                            <FiCheck size={30} color={"#A2BF83"} />
                          )}
                      </div>
                      <input
                        type={showpassword ? "text" : "password"}
                        placeholder="Password"
                        // className="input_complete_1"
                        value={password}
                        className={
                          password !== "" && password === confirm
                            ? "input_complete_1_check"
                            : "input_complete_1"
                        }
                        required
                        onChange={(e) => setpassword(e.target.value)}
                        onPaste={handlePaste}
                      />

                      {!showpassword && (
                        <div
                          className="eye"
                          onClick={() => setshowpassword(!showpassword)}
                        >
                          <AiFillEye size={30} />
                        </div>
                      )}
                      {showpassword && (
                        <div
                          className="eye"
                          onClick={() => setshowpassword(!showpassword)}
                        >
                          <AiFillEyeInvisible size={30} />
                        </div>
                      )}
                    </div>

                    <div className="signup_data">
                      <div className="null_container">
                        {confirm !== "" &&
                          password === confirm &&
                          emailRegex.test(email) && (
                            <FiCheck size={30} color={"#A2BF83"} />
                          )}
                      </div>
                      <input
                        type={showpassword1 ? "text" : "password"}
                        placeholder="Confirm Password"
                        // className="input_complete_1"
                        value={confirm}
                        className={
                          confirm !== "" && password === confirm
                            ? "input_complete_1_check"
                            : "input_complete_1"
                        }
                        required
                        onChange={(e) => setconfirm(e.target.value)}
                        onPaste={handlePaste}
                      />

                      {!showpassword1 && (
                        <div
                          className="eye"
                          onClick={() => setshowpassword1(!showpassword1)}
                        >
                          <AiFillEye size={30} />
                        </div>
                      )}
                      {showpassword1 && (
                        <div
                          className="eye"
                          onClick={() => setshowpassword1(!showpassword1)}
                        >
                          <AiFillEyeInvisible size={30} />
                        </div>
                      )}
                    </div>
                    {/* <div className="dateofbirth1">
                      The Password should contain : <br></br>
                      At least 8 characters in length<br></br>At least one
                      uppercase letter<br></br>At least one lowercase letter
                      <br></br>At least one digit<br></br>At least one special
                      character
                    </div> */}

                    {/* <p className="allabove">All above fields are required*</p> */}

                    {/* <p className="allabove">Use professional e-mail *</p> */}

                    <div className="agree_cont">
                      <input
                        type="checkbox"
                        name="agree"
                        id="agree"
                        required
                        onChange={(e) => setAgreeValue(e.target.checked)}
                      />
                      <p className="agree"> I agree to the terms. </p>
                    </div>
                    {/* <p className="error_user">
                      {email !== "" && !emailRegex.test(email)
                        ? "invalid Email!"
                        : ""}
                      {passwordregex.test(password) ? "" : "invalid Password!"}
                      <br />
                      {error?.response?.data?.message}
                    </p> */}
                    <div
                      className={`button_container ${
                        disabled4 ? "disabled" : ""
                      }`}
                      onClick={handleRegister}
                    >
                      <button
                        className="nextbutton"
                        type="submit"
                        disabled={disabled4}
                        onClick={() => {
                          setdone(true);
                        }}
                      >
                        {" "}
                        Sign Up{" "}
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CompleteProfileStep1;
