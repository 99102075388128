import React, { useState, useEffect } from "react";
import "./Applied_jobs.css";
import Image from "../../assets/images/profile/LibanLogo.png";
import { useNavigate } from "react-router-dom";

function Applied_Jobs(props) {
  const [daysSinceCreated, setDaysSinceCreated] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const createdDate = new Date(props.createdDate);
    const currentDate = new Date();

    const timeDifference = currentDate.getTime() - createdDate.getTime();
    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    if (days <= 0) {
      setDaysSinceCreated("Today");
    } else {
      setDaysSinceCreated(days);
    }
  }, []);
  const [statusClass, setstatusClass] = useState("");
  const changeStatusClass = () => {
    let status = props.buttontext;

    if (status === "Hired") {
      setstatusClass("greenbg");
    } else if (status === "To meet with") {
      setstatusClass("redbg");
    } else if (status === "Not matching") {
      setstatusClass("purplebg");
    } else if (status === "Qualified") {
      setstatusClass("cyanbg");
    } else if (status === "Matching pendinng") {
      setstatusClass("navybg");
    } else if (status === "Disqualified") {
      setstatusClass("pinkbg");
    } else if (status === "Call for interview") {
      setstatusClass("darkorgbg");
    } else if (status === "Not Reviewed") {
      setstatusClass("redbg");
    }
  };
  useEffect(() => {
    changeStatusClass();
  }, [props.buttontext]);

  const handleClick = () => {
    if (props.onClick) {
      props.onClick();
    }
  };
  
  return (
    <div
      className="AppliedJobsCard_container"
      onClick={handleClick}
    >
      <img
        src={process.env.REACT_APP_API + "/company/" + props.image}
        alt="Image"
        className="Libanlogo"
      />
      <div className="MARK_CONT1">
        <div className="MARK_POSITION1">{props.position}</div>
        <div className="MARK_LOCATION1">{props.location}</div>
        <div className="MARK_APPLY1">
          You applied this job {daysSinceCreated}{" "}
          {daysSinceCreated === "Today" ? <></> : <>{"days ago"} </>}
        </div>
      </div>
      <div className="MARK_CONT2">
        <div className="STATUS_TEXT">Status</div>
        <p className={`CALL_BUTTON ${statusClass}`}> {props.buttontext} </p>
      </div>
      <div className="MARK_CONT3">
        <div className="REASON_TEXT"> Reason </div>
        <input
          type="text"
          className="REASON_INPUT"
          placeholder=" Reason of approval or rejection"
          value={props.reason}
          disabled
        />
      </div>
    </div>
  );
}

export default Applied_Jobs;
